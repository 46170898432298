import request from '@index/utils/request'

export function handlePageQuery(data){
 return request({
    url: 'matchdetailstable/pageMatchDetailsTable',
    method: 'post',
    data
  })
}


export function handlePageMyMatchDetailsTable(data){
 return request({
    url: 'matchdetailstable/pageMyMatchDetailsTable',
    method: 'post',
    data
  })
}


export function handleDeleteMatchDetailsTable(matchId){
 return request({
    url: 'matchdetailstable/deleteMatchDetailsTable',
    method: 'post',
    params:{matchId:matchId}
  })
}



export function handleQueryTeamOfMatchJoin(matchId){//查询参赛方比赛的代表队
 return request({
    url: 'matchdetailstable/queryTeamOfMatchJoin',
    method: 'post',
    params:{matchId:matchId}
  })
}


export function handleJoinDownloadMatchdetailstable(data) { //下载我的赛程明细表
  return request({
    url: 'matchdetailstable/download',
    method: 'post',
	responseType: 'blob',
    data
  })
}
